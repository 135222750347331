
export function isValidEmailDomain(email, domains) {
    if (!email) return true;
    // Extract the domain part of the email
    const emailDomain = email.split('@')[1];

    // Check if the domain exists in the list of valid domains
    const isValid = domains.some(domain => domain.domain === emailDomain);
    return isValid;
}
