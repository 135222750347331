import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import dayjs from 'dayjs' //import dayjs in your main.js
import { createPinia } from 'pinia'
import relativeTime from 'dayjs/plugin/relativeTime';
import { isValidEmailDomain } from './helpers';
import * as Sentry from "@sentry/vue";
import VueFroala from 'vue-froala-wysiwyg';
import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
import 'froala-editor/js/third_party/embedly.min';
import 'froala-editor/js/third_party/font_awesome.min';
import 'froala-editor/js/third_party/spell_checker.min';
import 'froala-editor/js/third_party/image_tui.min';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';

dayjs.extend(relativeTime)
import { createManager } from '@vue-youtube/core';

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

const relativeDate = (dateTime) => dateTime ? dayjs(dateTime).fromNow() : null;
const friendlyDate = (dateTime, includeTime = false) => dateTime ? dayjs(dateTime).format(includeTime ? 'dddd, MMMM D, YYYY  h:mm A' : 'dddd, MMMM D, YYYY') : null;


const helpers = {
    relativeDate,
    friendlyDate,
    isValidEmailDomain
}



createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({
                provide: helpers, 
            render: () => h(App, props),
            })
            .use(plugin)
            .use(ZiggyVue)
            .use(createManager())
            .use(VueFroala)
            .use(createPinia());
            Sentry.init({
                app,
                dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
                environment: import.meta.env.VITE_APP_ENV,
            });

            app.config.globalProperties.$helpers = helpers;
            app.mount(el);
      
    },
    progress: {
        color: '#4B5563',
    },
});
